import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  const token: any = sessionStorage.getItem('token');
  if (token !=undefined) {
    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }
  return next.handle(request);
  }
}
