import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  // {
  //   navCap: 'Home',
  // },

  // {
  //   displayName: 'Dashboard',
  //   iconName: 'dashboard',
  //   route: '/dashboards/dashboard2',
  // },


  
  {
    navCap: 'Quick Actions',
  },
  {
    displayName: 'Add Project',
    iconName: 'home-plus',
    route: 'apps/projects',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/projects/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/projects/list',
      }
    ],
  },



  
  {
    displayName: 'Add User',
    iconName: 'user-plus',
    route: 'apps/roles',
    children: [
      {
        displayName: 'Add',
        iconName: 'point',
        route: 'apps/roles/add',
      },
      {
        displayName: 'List',
        iconName: 'point',
        route: 'apps/roles/list',
      }
    ],
  },




];
