import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppSettings, defaults } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

export const endpoint = 'https://backend.debco.co.ke/api/';
// export const endpoint = 'http://localhost:8000/api/';
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private http: HttpClient,public router:Router){
  }
  get notify(): Observable<Record<string, any>> {
    return this.notify$.asObservable();
  }


  private extractData(res:any) {
    const  body = res;
    return body || { };
  }

  login(data:any):Observable<any>{
    return this.http.post(endpoint + 'login/',data).pipe(
      map(this.extractData));
  }

  
  addEmployee(data:any):Observable<any>{
    return this.http.post(endpoint + 'add_employee/',data).pipe(
      map(this.extractData));
  }


  addCustomer(data:any):Observable<any>{
    return this.http.post(endpoint + 'add_customer/',data).pipe(
      map(this.extractData));
  }

  addItem(data:any):Observable<any>{
    return this.http.post(endpoint + 'items/',data).pipe(
      map(this.extractData));
  }

  
  
  selectCustomer(data:any):Observable<any>{
    return this.http.post(endpoint + 'select_customer/',data).pipe(
      map(this.extractData));
  }


  addProject(data:any):Observable<any>{
    return this.http.post(endpoint + 'project/',data).pipe(
      map(this.extractData));
  }

  deleteProject(id:any):Observable<any>{
    return this.http.delete(endpoint + 'project/'+id+'/').pipe(
      map(this.extractData));
  }
  addImage(data:any):Observable<any>{
    return this.http.post(endpoint + 'images/',data).pipe(
      map(this.extractData));
  }

  deleteImage(id:any):Observable<any>{
    return this.http.delete(endpoint + 'images/'+id+'/').pipe(
      map(this.extractData));
  }

  
  getProjects():Observable<any>{
    return this.http.get(endpoint + 'project/',).pipe(
      map(this.extractData));
  }


  
  getProject(id:any):Observable<any>{
    return this.http.get(endpoint + 'project/'+id+'/',).pipe(
      map(this.extractData));
  }

   
  updateProject(data:any):Observable<any>{
    return this.http.put(endpoint + 'project/'+data.id+'/',data).pipe(
      map(this.extractData));
  }

  addBranch(data:any):Observable<any>{
    return this.http.post(endpoint + 'branches/',data).pipe(
      map(this.extractData));
  }

  updateBranch(data:any):Observable<any>{
    return this.http.put(endpoint + 'branches/'+data.id+'/',data).pipe(
      map(this.extractData));
  }

  addDepartment(data:any):Observable<any>{
    return this.http.post(endpoint + 'department/',data).pipe(
      map(this.extractData));
  }

  addRole(data:any):Observable<any>{
    return this.http.post(endpoint + 'create_user/',data).pipe(
      map(this.extractData));
  }

  updateRole(data:any):Observable<any>{
    return this.http.put(endpoint + 'role/'+data.id+'/',data).pipe(
      map(this.extractData));
  }
  
  getRoles():Observable<any>{
    return this.http.get(endpoint + 'role/').pipe(
      map(this.extractData));
  }

  getItemClassfication():Observable<any>{
    return this.http.get(endpoint + 'item_classfication/').pipe(
      map(this.extractData));
  }

  getCodeList():Observable<any>{
    return this.http.get(endpoint + 'code_list/').pipe(
      map(this.extractData));
  }
  getCodeRecords():Observable<any>{
    return this.http.get(endpoint + 'codes/').pipe(
      map(this.extractData));
  }

  addEmploymentType(data:any):Observable<any>{
    return this.http.post(endpoint + 'employment_type/',data).pipe(
      map(this.extractData));
  }

  getEmploymentTypes():Observable<any>{
    return this.http.get(endpoint + 'employment_type/').pipe(
      map(this.extractData));
  }
  updateEmploymentType(data:any):Observable<any>{
    return this.http.put(endpoint + 'employment_type/'+data.id+'/',data).pipe(
      map(this.extractData));
  }
  getBranchs():Observable<any>{
    return this.http.get(endpoint + 'branch_list/').pipe(
      map(this.extractData));
  }

  getBranchList():Observable<any>{
    return this.http.get(endpoint + 'branches/').pipe(
      map(this.extractData));
  }

  generateCode():Observable<any>{
    return this.http.get(endpoint + 'generate_codes/').pipe(
      map(this.extractData));
  }
  classfications():Observable<any>{
    return this.http.get(endpoint + 'classfications/').pipe(
      map(this.extractData));
  }
  getBranch(id:any):Observable<any>{
    return this.http.get(endpoint + 'branches/'+id+'/').pipe(
      map(this.extractData));
  }
  
  getCompanies():Observable<any>{
    return this.http.get(endpoint + 'company/').pipe(
      map(this.extractData));
  }
  

  getDepartments():Observable<any>{
    return this.http.get(endpoint + 'department/').pipe(
      map(this.extractData));
  }
  
  private notify$ = new BehaviorSubject<Record<string, any>>({});

  getOptions() {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.notify$.next(this.options);
  }

  private options = defaults;

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }
}
